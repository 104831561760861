/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { TransformControls, useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"
import { useFrame, useThree } from "@react-three/fiber"
import {
  BodyProps,
  CannonWorker,
  ShapeType,
  useCompoundBody,
  useConvexPolyhedron,
  useTrimesh,
} from "@react-three/cannon"
import * as CANNON from "@react-three/cannon"
import { ShapeGeometry } from "three"
import { DragControls } from "three/examples/jsm/controls/DragControls"
import { Link, parsePath } from "gatsby"

type GLTFResult = GLTF & {
  nodes: {
    Sphere002: THREE.Mesh
  }
  materials: {}
}

type Props = {
  position?: CANNON.Triplet
  rotation?: CANNON.Triplet
  color?: string
  setState?
}

export default function Cockandballs(
  {
    position = [0, 10, 0],
    rotation = [0, 0, 0],
    color = "hotpink",
    setState = null,
  }: Props,
  { ...props }: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF(
    "/models/butatwhatcost.glb"
  ) as GLTFResult

  const bodies: BodyProps &
    {
      type: ShapeType
    }[] = [
    { type: "Sphere", position: [0, 0, 0], args: 0.95 },
    { type: "Sphere", position: [0.4, 0, 1.13], args: 1.2 },
    { type: "Sphere", position: [0.4, 0, -1.14], args: 1.2 },
    { type: "Sphere", position: [-5.95, 0, 0], args: 0.95 },
    {
      type: "Cylinder",
      position: [-5.95 / 2, 0, 0],
      rotation: [0, 0, Math.PI / 2],
      args: [1, 1, 5.95, 16],
    },
  ]

  const [ref, api] = useCompoundBody(() => ({
    mass: 10,
    position: position,
    rotation: rotation,
    type: "Dynamic",
    shapes: bodies,
  }))

  // const ref = useRef<THREE.Mesh>(null!)
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  // const [clicked, click] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  // useFrame((state, delta) => (ref!.current.rotation.x += 0.01))

  useEffect(() => {
    api.velocity.set(1, -25, 0)
  }, [])

  return (
    <group ref={group} {...props} dispose={null} scale={1}>
      <mesh
        castShadow
        ref={ref}
        geometry={nodes.Sphere002.geometry}
        scale={1}
        onClick={() => {
          if (setState) setState(false)
        }}
        onPointerOver={() => hover(true)}
        onPointerOut={() => hover(false)}
      >
        <meshStandardMaterial
          color={color}
          emissive={hovered ? "hotpink" : "black"}
        />
      </mesh>
    </group>
  )
}

useGLTF.preload("/models/butatwhatcost.glb")
