import { Canvas, useFrame, useThree } from "@react-three/fiber"
import React, { useState, useRef, useMemo, useEffect, useContext } from "react"
import * as THREE from "three"

import Layout from "../components/Layout"

import Cockandballs from "../../static/models/Cockandballs"
import {
  Debug,
  Physics,
  useBox,
  usePlane,
  useSphere,
} from "@react-three/cannon"
import * as CANNON from "@react-three/cannon"
import { ThemeContext } from "../components/ThemeProvider"
import { swapTheme, Themes } from "../utils/themes"
import Lantern from "../../static/models/Lantern"
import LetterW from "../../static/models/wip/LetterW"
import LetterI from "../../static/models/wip/LetterI"
import LetterP from "../../static/models/wip/LetterP"
import Ground from "../../static/models/Ground"

import * as s from "../styles/home.module.scss"
import { Link } from "gatsby"
import { OrbitControls, PerspectiveCamera } from "@react-three/drei"
import { Vector3 } from "three"

type objs = {
  id: number
  position: CANNON.Triplet
  rotation: CANNON.Triplet
  color: string
  sleepTime: number
}

const colors = [
  "1f1a38",
  "bdd358",
  "fbffb9",
  "bdc667",
  "ed7533",
  "6e628d",
  "fffcf2",
  "403d39",
  "252422",
  "25283d",
  "bac7be",
  "c2e1c2",
  "3a405a",
  "aec5eb",
  "623cea",
  "dde0bd",
  "cbe896",
  "aac0aa",
]

const getFallingObjects = (num: Number = 20) => {
  const objLocations: objs[] = []

  for (let i = 0; i < num; i++) {
    let x = Math.random() * 20 - 8
    let z = Math.random() * 24 - 14
    let y = i * 2 + 10
    // let y = 15

    let rx = Math.random() * Math.PI - Math.PI / 2
    let ry = Math.random() * Math.PI - Math.PI / 2
    let rz = Math.random() * Math.PI - Math.PI / 2

    // var randomColor =
    //   "#" +
    //   Math.floor(Math.random() * 16777215)
    //     .toString(16)
    //     .padStart(6, "0")
    var randomColor = "#" + colors[Math.floor(Math.random() * colors.length)]

    var sleepTime = 1000 * i

    objLocations.push({
      id: i,
      position: [x, y, z],
      rotation: [rx, ry, rz],
      color: randomColor,
      sleepTime: sleepTime,
    })
  }

  if (num >= 6) {
    objLocations[5].position = [-3.8, objLocations[5].position[1], -5]
  }

  return objLocations
}

const hashObj = (obj: {
  position: CANNON.Triplet
  rotation: CANNON.Triplet
}) => {
  return (
    obj.position[0] +
    obj.position[1] * 10 +
    obj.position[2] * 100 +
    obj.rotation[0] * 1000 +
    obj.rotation[1] * 10000 +
    obj.rotation[2] * 100000
  )
}

const Sun = ({ day, updateTheme }) => {
  // let day = theme === Themes.LIGHT
  const sunPosition = new THREE.Vector3(-120, 24, -80)

  const moonBlockRotation = new THREE.ArrowHelper(
    new THREE.Vector3()
      .subVectors(sunPosition, new THREE.Vector3(0, 0, 0))
      .normalize(),
    sunPosition
  )
  moonBlockRotation.lookAt(new THREE.Vector3(0, 0, 0))

  return (
    <>
      <mesh
        visible={!day}
        position={sunPosition.clone().add(new THREE.Vector3(12, -5, 12))}
        rotation={moonBlockRotation.rotation}
        onClick={e => {
          if (!day) e.stopPropagation()
        }}
      >
        <circleGeometry args={[9, 32]} />
        <meshStandardMaterial color={"pink"} colorWrite={false} />
      </mesh>
      <mesh position={sunPosition} onClick={() => swapTheme(updateTheme)}>
        <sphereGeometry args={[10]} />

        <meshStandardMaterial
          color={day ? "white" : "grey"}
          emissive={day ? "yellow" : "black"}
        />
      </mesh>
    </>
  )
}

const Scene = ({ day, updateTheme, pp, setpp }) => {
  const numFallingObjs = 40
  const objsScale = 0.4

  const fallingObjs = useMemo(() => getFallingObjects(numFallingObjs), [])
  const objStates = [...Array(numFallingObjs)].map(() => useState(true))

  const ref = useRef(null!)

  useEffect(() => {
    ref.current.shadow.camera.left = 30
    ref.current.shadow.camera.right = -30
    ref.current.shadow.camera.top = 10
    ref.current.shadow.camera.bottom = -10
  }, [])

  useThree(({ camera }) => {
    camera.position.set(9.4, 4, -3)
    camera.lookAt(new Vector3(0, 2.2, -1))
  })

  return (
    <>
      <ambientLight intensity={day ? 0.3 : 0.1} />
      <directionalLight
        ref={ref}
        castShadow
        intensity={day ? 0.8 : 0.1}
        position={[-120, 20, -80]}
      />
      <Sun day={day} updateTheme={updateTheme} />
      {/* <OrbitControls /> */}
      <Physics>
        {/* <Debug color={"red"}> */}
        <Ground position={[-30, 0, 24]} />
        {fallingObjs.map(obj => {
          switch (obj.id % 3) {
            case 0:
              return (
                objStates[obj.id][0] && (
                  <LetterW
                    key={hashObj(obj)}
                    position={obj.position}
                    rotation={obj.rotation}
                    color={obj.color}
                    scale={objsScale}
                    setState={objStates[obj.id][1]}
                    // sleep={obj.sleepTime}
                  />
                )
              )
            case 1:
              return (
                objStates[obj.id][0] && (
                  <LetterI
                    key={hashObj(obj)}
                    position={obj.position}
                    rotation={obj.rotation}
                    color={obj.color}
                    scale={objsScale}
                    setState={objStates[obj.id][1]}
                    // sleep={obj.sleepTime}
                  />
                )
              )
            case 2:
              return (
                objStates[obj.id][0] && (
                  <LetterP
                    key={hashObj(obj)}
                    position={obj.position}
                    rotation={obj.rotation}
                    color={obj.color}
                    scale={objsScale}
                    setState={objStates[obj.id][1]}
                    // sleep={obj.sleepTime}
                  />
                )
              )
          }
        })}
        <Lantern
          position={[2, -0.5, 8]}
          day={day}
          scale={0.6}
          updateTheme={updateTheme}
        />
        <LetterW
          staticW
          position={[-3, -0.2, 0]}
          rotation={[0, -Math.PI / 24, 0]}
          color={"#ff9505"}
        />
        <LetterI
          staticI
          scale={0.9}
          position={[-4, -0.5, -5]}
          color={"#016fb9"}
        />
        <LetterP
          staticP
          scale={0.8}
          position={[-2, -0.5, -8]}
          rotation={[0, -Math.PI / 6, 0]}
          color={"#db5461"}
        />
        {pp && (
          <Cockandballs
            rotation={[0, Math.PI / 1.5, 0]}
            position={[-3, 25, -4.5]}
            setState={setpp}
          />
        )}
        {/* </Debug> */}
      </Physics>
    </>
  )
}

export default function Wip() {
  const { theme, updateTheme } = useContext(ThemeContext)
  let day = theme === Themes.LIGHT
  const passphrase = "idobeballin"
  let p = 0

  useEffect(() =>
    document.addEventListener(
      "keydown",
      e => {
        if (e.key === passphrase[p]) {
          p++
          if (p === passphrase.length) {
            setPp(true)
            p = 0
          }
        } else {
          p = 0
        }
      },
      false
    )
  )

  const [pp, setPp] = useState(false)

  return (
    <Layout contentWidth="100vw">
      <div className={s.container}>
        <Canvas shadows={{ type: THREE.PCFSoftShadowMap }} className={s.canvas}>
          {/* <Canvas shadows camera={{ position: [10, 4, -3] }} className={s.canvas}> */}
          <PerspectiveCamera makeDefault fov={60} />
          {/* <Canvas shadows camera={{ position: [0, 26, 0] }}> */}
          <color attach="background" args={[day ? "lightblue" : "black"]} />
          <Scene day={day} updateTheme={updateTheme} pp={pp} setpp={setPp} />
        </Canvas>
        <div className={s.overlayText}>
          <h2>Welcome!</h2>
          <p> This page is currently still in development. </p>
          <p>
            You can check out my <Link to={"/blog"}>blog here.</Link>
          </p>
          <br />
          <i>Click one of the light sources to change the website's theme!</i>
        </div>
      </div>
    </Layout>
  )
}
