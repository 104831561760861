/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useEffect, useRef, useState } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"

import * as CANNON from "@react-three/cannon"
import { useCompoundBody } from "@react-three/cannon"
import { myEvents } from "../../../src/utils/types"
import { DragControls } from "three/examples/jsm/controls/DragControls"
import { extend, useThree } from "@react-three/fiber"
import { useDrag, useHover } from "@use-gesture/react"

extend({ DragControls })

type GLTFResult = GLTF & {
  nodes: {
    Sphere004: THREE.Mesh
  }
  materials: {}
}

type Props = {
  position?: CANNON.Triplet
  rotation?: CANNON.Triplet
  scale?: number
  mass?: number
  color?: string
  staticW?: boolean
  setState?
  sleep?: number
}

export default function LetterW(
  {
    position = [0, 0, 0],
    rotation = [0, 0, 0],
    scale = 1,
    mass = 10,
    color = "red",
    staticW = false,
    setState = null,
    sleep = null,
  }: Props,
  { ...props }: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF("/models/wip/letterW.glb") as GLTFResult

  position = [position[0], position[1] + 2.4, position[2]]

  const bodies: CANNON.BodyProps &
    {
      type: CANNON.ShapeType
    }[] = [
    {
      type: "Sphere",
      position: [0, 1.69 * scale, 2.15 * scale],
      rotation: [0, 0, 0],
      args: [0.98 * scale],
    },
    {
      type: "Cylinder",
      position: [0, 0.21 * scale, 1.6 * scale],
      rotation: [Math.PI / 9, 0, 0],
      args: [1 * scale, 1 * scale, 3.25 * scale, 16],
    },
    {
      type: "Sphere",
      position: [0, -1.43 * scale, 1 * scale],
      rotation: [0, 0, 0],
      args: [1 * scale],
    },
    {
      type: "Cylinder",
      position: [0, -0.57 * scale, 0.5 * scale],
      rotation: [-Math.PI / 6, 0, 0],
      args: [1 * scale, 1 * scale, 2 * scale, 16],
    },
    {
      type: "Sphere",
      position: [0, 0.3 * scale, 0],
      rotation: [0, 0, 0],
      args: [1 * scale],
    },
    {
      type: "Cylinder",
      position: [0, -0.57 * scale, -0.5 * scale],
      rotation: [Math.PI / 6, 0, 0],
      args: [1 * scale, 1 * scale, 2 * scale, 16],
    },
    {
      type: "Sphere",
      position: [0, -1.43 * scale, -1 * scale],
      rotation: [0, 0, 0],
      args: [1 * scale],
    },
    {
      type: "Cylinder",
      position: [0, 0.21 * scale, -1.6 * scale],
      rotation: [-Math.PI / 9, 0, 0],
      args: [1 * scale, 1 * scale, 3.25 * scale, 16],
    },
    {
      type: "Sphere",
      position: [0, 1.69 * scale, -2.15 * scale],
      rotation: [0, 0, 0],
      args: [0.98 * scale],
    },
  ]

  const [bodyRef, api] = useCompoundBody(() => ({
    mass: mass * scale,
    position: position,
    rotation: rotation,
    type: staticW ? "Static" : "Dynamic",
    shapes: bodies,
  }))

  const commitDie = e => {
    if (e.stopPropagation) e.stopPropagation()
    if (!setState) return
    console.log("Letter committed liven't")
    setState(false)
  }

  useEffect(() => {
    if (sleep) {
      api.sleep()
      setTimeout(() => {
        api.wakeUp()
      }, sleep)
    }
    bodyRef.current.addEventListener(myEvents.KILL, commitDie)
  })

  // const {
  //   size,
  //   viewport,
  //   camera,
  //   gl: { domElement },
  // } = useThree()

  // const aspect = size.width / viewport.width

  // const bind = useDrag(({ offset: [,], xy: [x, y], first, last }) => {
  //   console.log(x, y, first, last)
  //   if (first) {
  //     api.mass.set(0)
  //   }
  //   if (last) {
  //     api.mass.set(mass * scale)
  //   }
  //   api.position.set(
  //     (y - size.width / 2) / aspect,
  //     4,
  //     -(x - size.height / 2) / aspect
  //   )
  // }) as unknown as () => void

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        ref={bodyRef}
        geometry={nodes.Sphere004.geometry}
        scale={scale}
        castShadow
        // onClick={commitDie}
        // {...bind()}
      >
        <meshStandardMaterial color={color} />
      </mesh>
    </group>
  )
}

useGLTF.preload("/models/wip/letterW.glb")
