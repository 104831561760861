/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useEffect, useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"

import * as CANNON from "@react-three/cannon"
import { useCompoundBody } from "@react-three/cannon"
import { myEvents } from "../../../src/utils/types"

type GLTFResult = GLTF & {
  nodes: {
    Cylinder002: THREE.Mesh
  }
  materials: {}
}

type Props = {
  position?: CANNON.Triplet
  rotation?: CANNON.Triplet
  scale?: number
  color?: string
  staticP?: boolean
  setState?
  sleep?: number
}

export default function LetterP(
  {
    position = [0, 0, 0],
    rotation = [0, 0, 0],
    scale = 1,
    color = "yellow",
    staticP = false,
    setState = null,
    sleep = null,
  }: Props,
  { ...props }: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF("/models/wip/letterP.glb") as GLTFResult

  position = [position[0], position[1] + 3.06, position[2]]

  const bodies: CANNON.BodyProps &
    {
      type: CANNON.ShapeType
    }[] = [
    {
      type: "Sphere",
      position: [0, -2.06 * scale, 0.52 * scale],
      rotation: [0, 0, 0],
      args: [1 * scale],
    },
    {
      type: "Cylinder",
      position: [0, -0.46 * scale, 0.52 * scale],
      rotation: [0, 0, 0],
      args: [1 * scale, 1 * scale, 3.33 * scale, 16],
    },
    {
      type: "Sphere",
      position: [0, 1.27 * scale, 0.52 * scale],
      rotation: [0, 0, 0],
      args: [1 * scale],
    },
    {
      type: "Cylinder",
      position: [0, 1.24 * scale, -0.23 * scale],
      rotation: [Math.PI / 2.05, 0, 0],
      args: [1 * scale, 1 * scale, 1.4 * scale, 16],
    },
    {
      type: "Sphere",
      position: [0, 1.19 * scale, -0.88 * scale],
      rotation: [0, 0, 0],
      args: [1 * scale],
    },
    {
      type: "Sphere",
      position: [0, 0.54 * scale, -1.18 * scale],
      rotation: [0, 0, 0],
      args: [1 * scale],
    },
    {
      type: "Sphere",
      position: [0, -0.06 * scale, -0.88 * scale],
      rotation: [0, 0, 0],
      args: [1 * scale],
    },
  ]

  const [bodyRef, api] = useCompoundBody(() => ({
    mass: 10 * scale,
    position: position,
    rotation: rotation,
    type: staticP ? "Static" : "Dynamic",
    shapes: bodies,
  }))

  const commitDie = () => {
    if (!setState) return
    console.log("Letter committed liven't")
    setState(false)
  }

  useEffect(() => {
    if (sleep) {
      api.sleep()
      setTimeout(() => {
        api.wakeUp()
      }, sleep)
    }
    bodyRef.current.addEventListener(myEvents.KILL, commitDie)
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        ref={bodyRef}
        geometry={nodes.Cylinder002.geometry}
        scale={scale}
        castShadow
        // onClick={commitDie}
      >
        <meshStandardMaterial color={color} />
      </mesh>
    </group>
  )
}

useGLTF.preload("/models/wip/letterP.glb")
