/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useEffect, useMemo, useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"
import { useCylinder, useHeightfield, usePlane } from "@react-three/cannon"

import * as CANNON from "@react-three/cannon"
import { pointInCircle, vecPlus } from "../../src/utils/funcs"
import { myEvents } from "../../src/utils/types"

type GLTFResult = GLTF & {
  nodes: {
    Plane001: THREE.Mesh
    Plane002: THREE.Mesh
  }
  materials: {
    ["Material.003"]: THREE.MeshStandardMaterial
    ["Material.002"]: THREE.MeshStandardMaterial
  }
}

const getHeights = (points, args?): number[][] => {
  let xs = []
  let ys = []
  let zs = []

  for (let i = 0; i < points.count; i++) {
    xs.push(points.getX(i))
    ys.push(points.getZ(i))
    zs.push(points.getY(i))
  }

  let uniq = xs
    .map((_, i) => [xs[i], ys[i], zs[i]])
    .sort((a, b) => {
      if (a[0] == b[0]) {
        return a[2] > b[2] ? 1 : -1
      }
      return a[0] > b[0] ? 1 : -1
    })
    .filter((i, p, a) => {
      // Check for uniques
      return !p || !(i[0] == a[p - 1][0] && i[2] == a[p - 1][2])
    })

  let offsetX = uniq[0][0]
  let offsetZ = uniq[0][2]

  let heights = []

  for (let p = 0; p < uniq.length; p++) {
    let ix = uniq[p][0] - offsetX
    let iz = uniq[p][2] - offsetZ
    if (heights.length <= ix) {
      heights.push([])
    }
    if (heights[ix].length <= iz) {
      heights[ix].push([])
    }
    if (
      args &&
      args.radius &&
      args.circle &&
      !pointInCircle([ix, iz], args.radius, args.circle)
    ) {
      heights[ix][iz] = args.fall ?? -10
    } else {
      heights[ix][iz] = uniq[p][1]
    }
  }

  return heights
}

function Trigger({ ypos = 0 }) {
  const [ref] = usePlane(() => ({
    position: [0, ypos, 0],
    rotation: [-Math.PI / 2, 0, 0],
    isTrigger: true,
    onCollide: e => {
      e.body?.dispatchEvent({ type: myEvents.KILL })
    },
  }))

  return <mesh ref={ref}></mesh>
}

type Props = {
  position?: CANNON.Triplet
  rotation?: CANNON.Triplet
  scale?: number
  color?: string
  staticW?: boolean
}

export default function Ground(
  { position = [0, 0, 0], rotation = [0, 0, 0] }: Props,
  { ...props }: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF("/models/ground.glb") as GLTFResult

  let points = nodes.Plane001.geometry.attributes.position

  const heights = useMemo(
    () => getHeights(points, { radius: 24.2, circle: [24, 24] }),
    []
  )

  const [refHeightfield] = useHeightfield(() => ({
    position: position,
    rotation: vecPlus([-Math.PI / 2, 0, 0], rotation),
    args: [
      heights,
      {
        elementSize: 1,
      },
    ],
  }))

  const [refCyl] = useCylinder(() => ({
    position: vecPlus([24, -2.2, -24], position),
    rotation: rotation,
    args: [24, 24, 4, 12],
  }))

  return (
    <group ref={group} {...props} dispose={null} position={position}>
      <mesh
        geometry={nodes.Plane002.geometry}
        position={[24, 0, -24]}
        rotation={vecPlus([0, 0, 0], rotation)}
        // castShadow
        receiveShadow
      >
        <meshStandardMaterial color={"green"} />
      </mesh>
      <mesh ref={refHeightfield} />
      <mesh ref={refCyl} />
      <Trigger ypos={-2} />
    </group>
  )
}

useGLTF.preload("/models/ground.glb")
