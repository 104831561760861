/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useRef } from "react"
import { useGLTF, useHelper } from "@react-three/drei"
import { GLTF } from "three-stdlib"
import { PointLight, PointLightHelper, Vector3 } from "three"
import { vecMult, vecPlus } from "../../src/utils/funcs"
import {
  BodyProps,
  ShapeType,
  Triplet,
  useCompoundBody,
  useCylinder,
} from "@react-three/cannon"
import * as CANNON from "@react-three/cannon"
import { swapTheme } from "../../src/utils/themes"

type GLTFResult = GLTF & {
  nodes: {
    Cube: THREE.Mesh
    Cube001: THREE.Mesh
    Cylinder: THREE.Mesh
    Cube002: THREE.Mesh
    Cube003: THREE.Mesh
  }
  materials: {
    ["Material.001"]: THREE.MeshStandardMaterial
    ["Material.004"]: THREE.MeshStandardMaterial
    ["Material.005"]: THREE.MeshStandardMaterial
  }
}

type Props = {
  position?: CANNON.Triplet
  day?: boolean
  scale?: number
  updateTheme?
}

export default function Lantern(
  { position = [0, 0, 0], day = true, scale = 1, updateTheme = null }: Props,
  { ...props }: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF("/models/lantern.glb") as GLTFResult

  const bodies: BodyProps &
    {
      type: ShapeType
    }[] = [
    {
      type: "Box",
      position: vecMult([0, 4.875, 0], scale),
      rotation: [0, -0.24, 0],
      args: vecMult([1, 9.75, 1], scale),
    },
    {
      type: "Box",
      position: vecMult([-0.07, 8.8, -1.7], scale),
      rotation: [0, 0, 0],
      args: vecMult([0.6, 0.8, 2.8], scale),
    },
    {
      type: "Box",
      position: vecMult([0, 6.5, -2.7], scale),
      rotation: [0, 0, 0],
      args: vecMult([1.8, 2, 1.8], scale),
    },
  ]

  const [physicsRef] = useCompoundBody(() => ({
    position: position,
    type: "Static",
    shapes: bodies,
  }))

  const lightRef = useRef<PointLight>(null!)
  // useHelper(lightRef, PointLightHelper, 1, "white")

  return (
    <group ref={group} {...props} dispose={null} position={position}>
      <mesh ref={physicsRef} />
      <mesh
        geometry={nodes.Cube.geometry}
        material={nodes.Cube.material}
        position={vecMult([0, 4.88, 0], scale)}
        rotation={[0, -0.24, 0]}
        scale={vecMult([0.5, 4.85, 0.5], scale)}
        castShadow
      />
      <mesh
        geometry={nodes.Cube001.geometry}
        material={materials["Material.004"]}
        position={vecMult([0, 8.9, -0.6], scale)}
        rotation={[0, 0, 0.34]}
        scale={0.27 * scale}
        castShadow
      />
      <mesh
        geometry={nodes.Cylinder.geometry}
        material={materials["Material.005"]}
        position={vecMult([0, 7.89, -2.79], scale)}
        scale={vecMult([0.09, 0.67, 0.09], scale)}
        castShadow
      />
      <mesh
        geometry={nodes.Cube002.geometry}
        material={nodes.Cube002.material}
        position={vecMult([0.06, 5.84, -2.76], scale)}
        scale={vecMult([0.6, 1.14, 0.6], scale)}
        castShadow
      />
      <mesh
        geometry={nodes.Cube003.geometry}
        position={vecMult([0.06, 5.85, -2.76], scale)}
        scale={vecMult([0.6, 1.14, 0.6], scale)}
        onClick={() => swapTheme(updateTheme)}
      >
        <meshStandardMaterial
          color={"yellow"}
          emissive={day ? "black" : "yellow"}
        />
      </mesh>
      <pointLight
        ref={lightRef}
        position={vecMult([0.06, 6, -2.76], scale)}
        castShadow
        intensity={day ? 0 : 0.6}
      />
    </group>
  )
}

useGLTF.preload("/models/lantern.glb")
