function slugify(text) {
  return text
    .toString() // Cast to string (optional)
    .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
}

const vecPlus = ([x1, y1, z1], [x2, y2, z2]): [number, number, number] => {
  return [x1 + x2, y1 + y2, z1 + z2]
}

const vecMinus = ([x1, y1, z1], [x2, y2, z2]): [number, number, number] => {
  return vecPlus([x1, y1, z1], vecMult([x2, y2, z2], -1))
}

const vecMult = ([x1, y1, z1], s): [number, number, number] => {
  return [x1 * s, y1 * s, z1 * s]
}

const euclidianDistance2 = ([x1, y1], [x2, y2]) => {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
}

const pointInCircle = ([sx, sy], r, [px, py]) => {
  return euclidianDistance2([sx, sy], [px, py]) < r
}

export {
  slugify,
  vecPlus,
  vecMinus,
  vecMult,
  pointInCircle,
  euclidianDistance2,
}
