/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useEffect, useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"

import * as CANNON from "@react-three/cannon"
import { useCompoundBody, useSphere, useSpring } from "@react-three/cannon"
import { myEvents } from "../../../src/utils/types"

type GLTFResult = GLTF & {
  nodes: {
    Sphere001: THREE.Mesh
    Sphere003: THREE.Mesh
  }
  materials: {}
}

type Props = {
  position?: CANNON.Triplet
  dotPosition?: CANNON.Triplet
  rotation?: CANNON.Triplet
  scale?: number
  color?: string
  staticI?: boolean
  staticDot?: boolean
  lamp?: boolean
  day?: boolean
  setState?
  sleep?: number
}

export default function LetterI(
  {
    position = [0, 0, 0],
    dotPosition = [0, 7, 0],
    rotation = [0, 0, 0],
    scale = 1,
    color = "blue",
    staticI = false,
    staticDot = false,
    lamp = false,
    day = true,
    setState = null,
    sleep = null,
  }: Props,
  { ...props }: JSX.IntrinsicElements["group"]
) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF("/models/wip/letterI.glb") as GLTFResult

  position = [position[0], position[1] + 2.66, position[2]]
  dotPosition = [dotPosition[0], dotPosition[1] - 2.66, dotPosition[2]]

  const bodies: CANNON.BodyProps &
    {
      type: CANNON.ShapeType
    }[] = [
    {
      type: "Sphere",
      position: [0, -1.66 * scale, 0],
      rotation: [0, 0, 0],
      args: [1 * scale],
    },
    {
      type: "Cylinder",
      position: [0, 0.15 * scale, 0],
      rotation: [0, 0, 0],
      args: [1 * scale, 1 * scale, 3.25 * scale, 16],
    },

    {
      type: "Sphere",
      position: [0, 1.67 * scale, 0],
      rotation: [0, 0, 0],
      args: [1 * scale],
    },
  ]

  const translation = new THREE.Matrix4().makeTranslation(
    dotPosition[0],
    dotPosition[1],
    dotPosition[2]
  )
  const rotationX = new THREE.Matrix4().makeRotationX(rotation[0])
  const rotationY = new THREE.Matrix4().makeRotationY(rotation[1])
  const rotationZ = new THREE.Matrix4().makeRotationZ(rotation[2])
  const scaleM = new THREE.Matrix4().makeScale(scale, scale, scale)

  const transformation = scaleM.multiply(
    rotationX.multiply(rotationY.multiply(rotationZ.multiply(translation)))
  )

  var t = new THREE.Vector3(),
    r = new THREE.Quaternion(),
    s = new THREE.Vector3()

  transformation.decompose(t, r, s)

  t.addVectors(t, new THREE.Vector3(position[0], position[1], position[2]))

  const [dotRef] = useSphere(() => ({
    mass: 2 * scale,
    position: t.toArray(),
    rotation: rotation,
    type: staticDot ? "Static" : "Dynamic",
    args: [1 * scale],
  }))

  const [bodyRef, api] = useCompoundBody(() => ({
    mass: 10 * scale,
    position: position,
    rotation: rotation,
    type: staticI ? "Static" : "Dynamic",
    shapes: bodies,
  }))

  useSpring(dotRef, bodyRef, {
    stiffness: 69,
    damping: 6,
    restLength: 0,
    worldAnchorB: t.toArray(),
  })

  const commitDie = () => {
    if (!setState) return
    console.log("Letter committed liven't")
    setState(false)
  }

  useEffect(() => {
    if (sleep) {
      api.sleep()
      setTimeout(() => {
        api.wakeUp()
      }, sleep)
    }

    bodyRef.current.addEventListener(myEvents.KILL, commitDie)
    dotRef.current.addEventListener(myEvents.KILL, commitDie)
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        ref={bodyRef}
        geometry={nodes.Sphere001.geometry}
        scale={scale}
        castShadow
      >
        <meshStandardMaterial color={color} />
      </mesh>
      <mesh
        ref={dotRef}
        geometry={nodes.Sphere003.geometry}
        scale={scale}
        castShadow={!lamp}
      >
        <meshStandardMaterial
          color={color}
          emissive={lamp && !day ? "yellow" : "black"}
        />
      </mesh>
      {lamp && (
        <pointLight
          castShadow
          intensity={day ? 0 : 0.6}
          position={dotPosition}
        />
      )}
    </group>
  )
}

useGLTF.preload("/models/wip/letterI.glb")
