import { Node } from "gatsby"
import { ImageDataLike } from "gatsby-plugin-image"

export interface CardNode extends Node {
  slug: string
  frontmatter: {
    title: string
    date: string
    description: string
    preview_image_alt: string
    preview_image: ImageDataLike
  }

  [key: string]: unknown // ...other fields specific to this type of node
}

export enum myEvents {
  KILL = "kys",
}
